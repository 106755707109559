import React from "react";
import DialButton from './DialButton';

export const Header = (props) => {
  const handleNavClick = (event, target) => {
    event.preventDefault();
    const element = document.querySelector(target);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
      window.history.pushState(null, null, " ");
    }
  };

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <a
    href="#modalForm"
    className="btn btn-custom btn-lg page-scroll"
    onClick={(e) => handleNavClick(e, "#modal-form")}
  >
    Request a quote
  </a>
  <DialButton style={{marginTop: '20%'}} phoneNumber="+8479994348" />
</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
