import React from 'react';

const DialButton = ({ phoneNumber }) => {
  return (
    <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none' }} className="dial-button">
      <button style={buttonStyle}>Call Now</button>
    </a>
  );
};

// Optional: Define some button styles
const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#28a745',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  fontSize: '16px',
  cursor: 'pointer',
};

// CSS to hide the button on desktop
const styles = `
  @media (min-width: 768px) {
    .dial-button {
      display: none;
    }
  }
`;

// Inject styles into the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default DialButton;
